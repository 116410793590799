.link-no-underline {
    text-decoration: none !important; /* Remove underline */
  }
  
  .centered-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .carousel-item {
    text-align: center;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.50rem;
    border: 1px solid #dfe7ef;
    color: maroon;
    background: #F3F3F3;
    font-weight: 700;
    border-radius: 6px;
    transition: box-shadow 0.2s;
}

.p-card .p-card-content {
  padding: 0.25rem 0;
}
.p-card .p-card-body {
   padding: 0.25rem;
}