li a {
    text-decoration: none;
    color: white !important;
    font-weight: bold;
}

.labelcolor {
    text-decoration: none;
    color: maroon !important;
    font-weight: bold;
}

.p-menubar{
    padding: 0.1rem;
    background-color: #F30D05;
    color:white;
}

.p-menubar .p-menuitem-link {
    padding: 0.5rem 0rem 0.5rem 0rem;
    background-color: #F30D05;
    color:white;
    
}

.p-menubar ul {
    padding-left: 0rem !important;
    margin-bottom: 0rem !important;
}

.p-menubar-root-list{
    margin-left: 15px;
}

.sticky-menubar {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #ffffff; /* Set the background color as needed */
    border-bottom: 1px solid #ccc; /* Optional: Add a border for separation */
  }
  