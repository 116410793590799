.back {
  background: #e2e2e2;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.loginbody{
  background-image: url("images/LoginBack.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.div-center {
  width: 350px;
  /* height: 400px; */
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 1em 1em 1em;
  border-bottom: 2px solid #ccc;
  display: table;
  border-radius: 30px;
}

div.content{
    display: table-cell;
    vertical-align: middle;
}

.productappLogo{
    width: 100px;
    display: block;
    margin-top: -9px;
}

input[type=text],[type=password]{
    border:none;
    background-color: transparent;
    resize: none;
    outline: none;
    border-radius: 1px solid #bebebe;
    width: 100%;
    font-size: 17px;
}

.custom-button{
    border-radius: 30px;
    width: 100%;
    height: 45px;
    font-weight: 500;
    margin-bottom: 5px;
    background: #F30D05 !important;
    border: 1px solid #F30D05 !important;
}

.customfg-button{
  border-radius: 30px;
  width: 100%;
  height: 45px;
  font-weight: 500;
  background: #F30D05 !important;
  border: 1px solid #F30D05 !important;
}

.forgotbutton{
    margin-right: -0.2rem; 
}